import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getEvaluationDetails, saveEvaluation } from 'containers/Evaluation/api';
import {
  FETCH_EVALUATION_DETAILS_COMPLETE,
  FETCH_EVALUATION_DETAILS,
  EVALUATION_SAVE_ACTION,
  EVALUATION_SAVE_ACTION_COMPLETE,
} from 'containers/Evaluation/constants';
import { makeEvaluation } from 'containers/Evaluation/selector';

export function* evaluationDetailsSaga({ id }) {
  try {
    const { evaluation, step, assessmentSectionCandidateId } = yield call(getEvaluationDetails, { id });
    yield put({
      type: FETCH_EVALUATION_DETAILS_COMPLETE,
      evaluation,
      step,
      error: null,
      id: assessmentSectionCandidateId,
    });
  } catch (error) {
    yield put({ type: FETCH_EVALUATION_DETAILS_COMPLETE, error, evaluation: false, step: {} });
  }
}

export function* saveSaga({ formData, onClose, recommendationOption }) {
  try {
    const { rating, id, evaluationComment, recommendation, files } = yield select(makeEvaluation(), {
      key: 'evaluation',
    });
    const response = yield call(saveEvaluation, {
      rating,
      recommendation: recommendationOption || recommendation,
      id,
      evaluationComment,
      formData,
      files,
    });
    if (response?.data?.evaluateAction) {
      onClose(recommendation);
      yield put({ type: EVALUATION_SAVE_ACTION_COMPLETE, saveLoading: false, recommendation, error: false });
    } else {
      yield put({ type: EVALUATION_SAVE_ACTION_COMPLETE, saveLoading: false, recommendation, error: true });
    }
  } catch (error) {
    yield put({ type: EVALUATION_SAVE_ACTION_COMPLETE, error: true, saveLoading: false });
  }
}

export default function* evaluationSaga() {
  yield takeLatest(FETCH_EVALUATION_DETAILS, evaluationDetailsSaga);
  yield takeLatest(EVALUATION_SAVE_ACTION, saveSaga);
}
