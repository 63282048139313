/**
 *
 * Status
 *
 */

import React, { memo, useMemo } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getFormattedRecommendationStatus } from './configMap';

function Status({ status, leadingIcon }) {
  const data = useMemo(() => getFormattedRecommendationStatus(status), [status]);

  return status && data?.label ? (
    <div
      className={classNames(
        'flex text-center w-fit font-medium text-sm justify-center items-center gap-2.5',
        `${get(data, 'theme.bg', '')}`,
        `${get(data, 'theme.border', '')}`,
        `${get(data, 'theme.borderRadius', '')}`,
        `${get(data, 'theme.padding', 'py-1 px-3')}`,
        `${get(data, 'theme.height', '')}`,
      )}
    >
      {leadingIcon && leadingIcon}
      <span className={classNames(get(data, 'theme.text', ''))}>{data?.label}</span>
    </div>
  ) : (
    <> </>
  );
}

Status.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default memo(Status);
