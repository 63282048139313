import { GrowthBook } from '@growthbook/growthbook-react';
import Mixpanel from 'mixpanel-browser';
import { get } from 'lodash';

const growthbook = new GrowthBook({
  apiHost: process.env.GB_API_HOST || 'https://gb-proxy.talview.com',
  clientKey: process.env.GB_CLIENT_KEY || 'sdk-rVvxcOXY6UomO17g',
  enableDevMode: process.env.NODE_ENV !== 'production',
  trackingCallback(experiment, result) {
    if (get(Mixpanel, '__loaded')) {
      Mixpanel.track('$experiment_started', {
        'Experiment name': experiment.key,
        'Variant name': result.variationId,
        $source: 'growthbook',
      });
    }
  },
});
export default growthbook;
