/* eslint-disable no-unused-expressions */
import { useCallback } from 'react';
import VideoServiceInterface from 'containers/IRRedirect/videoServiceInterface';
import { providerOptions } from 'contexts/VideoProvider/constants';
import { SIGNAL_MESSAGE } from 'containers/InterviewRooms/signalConstants';
import useIsTrackEnabled from './useIsTrackEnabled';

export default function useLocalAudioToggle(props) {
  const { localAudioTrack, onError = () => {} } = props || {};
  const { irVideoServiceProvider } = VideoServiceInterface();
  const isTwilio = irVideoServiceProvider === providerOptions.TWILIO;
  const audioTrack = localAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      if (!isTwilio) {
        audioTrack?.isMuted ? audioTrack?.unmute() : audioTrack?.mute();
      } else {
        audioTrack?.isEnabled ? audioTrack?.disable() : audioTrack?.enable();
      }
    } else onError({ signalMessage: SIGNAL_MESSAGE.ENABLE_MIC_PERMISSIONS });
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled];
}
