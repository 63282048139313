export const ERROR_NAME = { ABORT_ERROR: 'AbortError', NOT_ALLOWED_ERROR: 'NotAllowedError' };
export const ERROR_MESSAGE = {
  LIVE_KIT_ERROR_MESSAGE: 'Access to the feature "display-capture" is disallowed by permission policy',
  TWILIO_ERROR_MESSAGE: 'The request is not allowed by the user agent or the platform in the current context',
};

export const providersPriority = ['LIVEKIT', 'TWILIO', 'LIVEKIT_SELF_HOSTED'];

export const providerOptions = {
  LIVEKIT: 'LIVEKIT',
  TWILIO: 'TWILIO',
  LIVEKIT_SELF_HOSTED: 'LIVEKIT_SELF_HOSTED',
};
