import { gql } from '@apollo/client';

export const GET_EVALUATION = gql`
  query evaluation($id: bigint!) {
    assessment_section_candidate_by_pk(id: $id) {
      id
      panel_candidates {
        evaluations(where: { is_deleted: { _eq: false } }, order_by: { id: desc }) {
          recommendation
          form_instances {
            data
          }
          evaluation_ratings {
            parameter {
              id
            }
          }
        }
        panel {
          user {
            id
            email
          }
        }
      }
      assessment_section {
        section {
          form {
            id
            custom_url
            is_editable
          }
          section_parameters(where: { is_deleted: { _eq: false } }) {
            parameter {
              category {
                id
                name
              }
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const EVALUATE_ACTION = gql`
  mutation evaluate($data: [evaluateActionInput!]) {
    evaluateAction(actionType: "evaluate", data: $data)
  }
`;
