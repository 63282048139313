/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  JOIN_ROOM_COMPLETE,
  SET_BROWSER_PERMISSION_DENIED,
  SET_IS_CONNECTING,
  SET_VIDEO_TRACK,
  UPDATE_CUSTOM_TOKEN,
} from 'containers/SecondaryCamera/constants';

export const initialState = {
  customTokenData: null,
  userDefaultRole: null,
  roomDetails: {
    roomToken: null,
    roomId: null,
    videoServiceProvider: null,
    participantId: null,
    candidateRecordingConsent: null,
  },
  urlRecordingParam: false,
  videoTrack: null,
  isConnecting: false,
  isBrowserPermissionDenied: false,
};

const secondaryCameraReducer = (state = initialState, action) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case UPDATE_CUSTOM_TOKEN:
        draft.customTokenData = action.customTokenData;
        draft.userDefaultRole = action.userDefaultRole;
        break;
      case JOIN_ROOM_COMPLETE:
        draft.roomDetails = action.roomDetails;
        draft.urlRecordingParam = action.urlRecordingParam;
        break;
      case SET_VIDEO_TRACK:
        draft.videoTrack = action.track;
        break;
      case SET_IS_CONNECTING:
        draft.isConnecting = action.status;
        break;
      case SET_BROWSER_PERMISSION_DENIED:
        draft.isBrowserPermissionDenied = action.status;
        break;
    }
  });

export default secondaryCameraReducer;
