export const mixpanelEvents = {
  MEETING_PARTICIPANT_JOINED: 'meeting_participant_joined',
  MEETING_PARTICIPANT_LEFT: 'meeting_participant_left',
  MEETING_PARTICIPANT_MUTED: 'meeting_participant_muted',
  MEETING_PARTICIPANT_UNMUTED: 'meeting_participant_unmuted',
  MEETING_PARTICIPANT_CAMERA_ON: 'meeting_participant_camera_on',
  MEETING_PARTICIPANT_CAMERA_OFF: 'meeting_participant_camera_off',
  MEETING_CANDIDATE_ACCEPTED_VIDEO_REQUEST: 'meeting_candidate_accepted_video_request',
  MEETING_CANDIDATE_REJECTED_VIDEO_REQUEST: 'meeting_candidate_rejected_video_request',
  MEETING_PARTICIPANT_STARTED_SCREEN_SHARE: 'meeting_participant_started_screen_share',
  MEETING_PARTICIPANT_STOPPED_SCREEN_SHARE: 'meeting_participant_stopped_screen_share',
  MEETING_INTERVIEWER_VIEW_RESUME: 'meeting_interviewer_view_resume',
  MEETING_INTERVIEWER_VIEW_JOB_DESCRIPTION: 'meeting_interviewer_view_job_description',
  MEETING_INTERVIEWER_GUIDE_OPEN: 'meeting_interviewer_guide_open',
  MEETING_INTERVIEWER_LANDING_PAGE_RESCHEDULE: 'meeting_interviewer_landing_page_reschedule',
  MEETING_INTERVIEWER_REQUEST_VIDEO: 'meeting_interviewer_request_video',
  SCHEDULED_DURATION: 'scheduled_duration',
  ACTUAL_DURATION: 'actual_duration',
  MEETING_SCHEDULED_ACTUAL_DURATION_TAT: 'meeting_scheduled_actual_duration_tat',
  MEETING_PARTICIPANT_POOR_INTERNET_CONNECTION_ALERTED: 'meeting_participant_poor_internet_connection_alerted',
  MEETING_PARTICIPANT_ERROR_DROP: 'meeting_participant_error_drop',
  MEETING_PARTICIPANT_REJOIN_ERROR_DROP: 'participant_meeting_rejoin_tech_drop',
  INTERVIEWER_EVALUATED_CANDIDATE_ON_MEETING_EXIT: 'interviewer_evaluated_candidate_on_meeting_exit',
  INTERVIEWER_DECLINED_EVALUATION_ON_MEETING_EXIT: 'interviewer_declined_evaluation_on_meeting_exit',
  INTERVIEWER_OPENED_EVALUATION_IN_MEETING: 'interviewer_opened_evaluation_in_meeting',
  INTERVIEWER_LEFT_MEETING_AFTER_EVALUATION: 'interviewer_left_meeting_after_evaluation',
  MEETING_LOADING_TIME_TAKEN: 'meeting_loading_time_taken',
  INTERVIEW_ROOMS_FALLBACK_VIDEO_SERVICE_PROVIDER: 'interview_rooms_fallback_video_service_provider',
  MEETING_STARTED: 'meeting_started',
  MEETING_ENDED: 'meeting_ended',
  CODE_JAM_PARTICIPANT_OPENED: 'code_jam_participant_opened',
  CODE_JAM_PARTICIPANT_LANGUAGE_CHANGED: 'code_jam_participant_language_changed',
  CODE_JAM_PARTICIPANT_CODE_RESET: 'code_jam_participant_code_reset',
  CODE_JAM_PARTICIPANT_OPENED_CONSOLE: 'code_jam_participant_opened_console',
  CODE_JAM_PARTICIPANT_CLOSED_CONSOLE: 'code_jam_participant_closed_console',
  CODE_JAM_CODE_SAMPLE_TEST_RUN: 'code_jam_code_sample_test_run',
  CODE_JAM_QUICK_TEST_RUN: 'code_jam_quick_test_run',
  CODE_JAM_NORMAL_MINIMIZED_VIEW_SWITCHED: 'code_jam_normal_minimized_view_switched',
  CODE_JAM_PARTICIPANT_COMPACT_VIEW_SWITCHED: 'code_jam_participant_compact_view_switched',
  CODE_JAM_INTERVIEW_SCREEN_MAXIMIZED: 'code_jam_interview_screen_maximized',
  PRE_JOIN_VIDEO_TOGGLE_SWITCHED: 'pre_join_video_toggle_switched',
  PRE_JOIN_AUDIO_TOGGLE_SWITCHED: 'pre_join_audio_toggle_switched',
  PRE_JOIN_PARTICIPANT_INTERVIEW_JOINED: 'pre_join_participant_interview_joined',
  PRE_JOIN_PARTICIPANT_LANDED: 'pre_join_participant_landed',
  PRE_JOIN_PARTICIPANT_TIME_SPENT: 'pre_join_participant_time_spent',
  PRE_JOIN_PARTICIPANT_SETTING_CHANGED: 'pre_join_participant_setting_changed',
  BOARD_OPENED: 'white_board_participant_opened',
  BOARD_CLOSED: 'white_board_participant_closed',
  POST_INTERVIEW_FEEDBACK_PAGE_OPENED: 'post_interview_feedback_page_opened',
  POST_INTERVIEW_FEEDBACK_RATING_SELECTED: 'post_interview_feedback_rating_selected',
  POST_INTERVIEW_FEEDBACK_REVIEW_PROVIDED: 'post_interview_feedback_review_provided',
  CHATTING_EVERYONE_PARTICIPANT_OPENED: 'chatting_everyone_participant_opened',
  CHATTING_INTERVIEWER_PARTICIPANT_OPENED: 'chatting_interviewer_participant_opened',
  CHATTING_EVERYONE_PARTICIPANT_SEND: 'chatting_everyone_participant_send',
  CHATTING_INTERVIEWER_PARTICIPANT_SEND: 'chatting_interviewer_participant_send',
  TAB_SWITCH_DETECTED: 'meeting_candidate_switched_tab_alerted',
  TAB_SWITCH_DETECTED_THRESHOLD_TIME: 'meeting_candidate_stayed_out_window_alerted',
  VP9_ENABLED: 'vp9_enabled',
  VIDEO_CAPTURE_DEFAULT_RESOLUTION: 'video_capture_default_resolution',
  ROOM_DISCONNECTED: 'room_disconnected',
};
