import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/IR/Button';

export default function IRMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const [clickValue, updateClickValue] = useState('123');

  const onLeaveClick = () => {
    const payload = { type: 'IRleaveBtnClick', leaveMeeting: true };
    updateClickValue(Math.random() * 100);
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.webkit.messageHandlers.iosClient.postMessage(JSON.stringify(payload));
    } else if (/android/i.test(userAgent)) {
      // eslint-disable-next-line no-undef
      AndroidForms.formData(JSON.stringify(payload));
    } else {
      window.parent.postMessage(payload, '*');
    }
  };
  return (
    <div className="w-screen h-screen flex flex-col overflow-hidden 0 font-roboto">
      <div className="h-full w-full flex justify-center items-center ">
        <Button
          leadingIcon={<FontAwesomeIcon icon={faPhone} className="h-6 rotate-[135deg]" />}
          buttonClassname="h-9 w-28 flex flex-col items-center justify-evenly rounded-lg dark:bg-red-500 bg-red-500 hover:bg-red-600 hover:text-white
          dark:hover:bg-red-600 hover:bg-red-500 dark:hover:text-white hover:text-black dark:text-white text-white"
          onClick={onLeaveClick}
        >
          Leave
        </Button>
      </div>
      <p className="text-white bold">{clickValue}</p>
    </div>
  );
}
