import React from 'react';
import VideoProvider from 'contexts/VideoProvider';
import InterviewRoomsRedirect from 'containers/IRRedirect/Loadable';
import IRMobile from 'containers/IRMobile';
import TechnicalErrorPage from 'components/IR/TechnicalErrorPage';
import InterviewRooms from 'containers/InterviewRooms/Loadable';
import RecordingPlayback from 'containers/RecordingPlayback/Loadable';
import InterviewInsights from 'containers/InterviewInsights/Loadable';
import FeedbackPage from 'containers/FeedbackPage/loadable';
import Evaluation from 'containers/Evaluation';
import SecondaryCamera from 'containers/SecondaryCamera/Loadable';
import ParticipantLeavePage from 'components/IR/ParticipantLeavePage';

const routes = () => [
  {
    path: '/ir/redirect/*',
    element: (
      <VideoProvider>
        <InterviewRoomsRedirect />
      </VideoProvider>
    ),
  },
  {
    path: '/ir/error',
    element: <TechnicalErrorPage />,
  },
  {
    path: '/mobile',
    element: (
      <VideoProvider>
        <IRMobile />
      </VideoProvider>
    ),
  },
  {
    path: '/interview-rooms/*',
    element: (
      <VideoProvider>
        <InterviewRooms />
      </VideoProvider>
    ),
  },
  {
    path: '/secondary-camera',
    element: (
      <VideoProvider>
        <SecondaryCamera />
      </VideoProvider>
    ),
  },
  {
    path: '/feedback',
    element: <FeedbackPage />,
  },
  {
    path: '/evaluation',
    element: <Evaluation />,
  },
  {
    path: '/thankyou',
    element: <ParticipantLeavePage />,
  },
  {
    path: '/playback',
    element: <RecordingPlayback />,
  },
  {
    path: '/insights',
    element: <InterviewInsights />,
  },
];
export default routes;
