import produce from 'immer';
import { set } from 'lodash';
import { setItem, loadState } from 'utils/store';

import { SAVE_STATE_ACTION, LOAD_STATE_ACTION, GLOBAL_ERROR } from './constants';

export const initialState = { auth: 'none', error: false };
/* eslint-disable default-case, no-param-reassign */
export const savedReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_STATE_ACTION:
        draft = loadState();
        break;
      case SAVE_STATE_ACTION:
        set(draft, `${action.key}`, action.data);
        setItem(action.key, action.data);
        break;
      case GLOBAL_ERROR:
        draft.error = action.error;
        break;
    }
  });

export default savedReducer;
