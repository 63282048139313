import React from 'react';
import EvaluationButtonStrip from 'components/IR/EvaluationButtonStrip';
import ParticipantItem from 'components/ParticipantItem';
import PropTypes from 'prop-types';

export function EvaluationBottomPanel({
  candidateDetails,
  recommendation,
  saveLoading,
  submitEvaluation,
  updateComment,
  evaluationComment,
  evaluated,
}) {
  return (
    <div className="flex justify-between items-center w-full dark:bg-neutral-700 bg-slate-200 evaluation_bottom_panel rounded-b-lg">
      <div className="w-[23.75rem] overflow-hidden mr-2 pl-4 hidden">
        <ParticipantItem
          data={candidateDetails}
          // description="Software Engineer"
          // evaluationStatus="Pending"
          avatarSize="xl"
        />
      </div>
      <textarea
        className="resize-none dark:bg-neutral-900 bg-slate-100 font-normal ml-4 my-2 text-sm rounded-lg h-16 p-4 dark:text-white text-black outline-none border-none grow"
        placeholder="Type comment here..."
        value={evaluationComment}
        onChange={e => updateComment(e.target.value)}
      />
      <div className="w-[23.75rem] justify-self-end">
        <EvaluationButtonStrip
          evaluated={evaluated}
          recommendation={recommendation}
          saveLoading={saveLoading}
          save={submitEvaluation}
        />
      </div>
    </div>
  );
}

EvaluationBottomPanel.propTypes = {
  candidateDetails: PropTypes.object,
  recommendation: PropTypes.string,
  saveLoading: PropTypes.bool,
  submitEvaluation: PropTypes.func,
  updateComment: PropTypes.func,
  evaluated: PropTypes.bool,
};

export default EvaluationBottomPanel;
