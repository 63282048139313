import React, { useCallback } from 'react';
import { map, trim } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
function ViewFormData(props) {
  const { formData, type } = props;
  function dynamicClassValues(typeValue) {
    switch (typeValue) {
      case 'basic':
        return {
          label: 'dark:text-neutral-400 text-slate-400 text-sm font-normal capitalize',
          value: 'dark:text-white text-black text-base font-semibold',
        };

      default:
        return {
          label: 'text-lg dark:text-slate-500 text-neutral-100',
          value: 'dark:text-slate-800 text-neutral-500',
        };
    }
  }

  const enableTooltipForEmail = useCallback(
    item => type === 'basic' && item?.label === 'email' && trim(item?.value) !== '',
    [type],
  );

  return (
    <div
      className={classNames(
        `w-full grid grid-cols-2 overflow-x-hidden overflow-y-scroll gap-x-1 sm:gap-x-8 no-scrollbar`,
        !type && 'p-5 h-5/6',
      )}
    >
      {formData &&
        map(formData, (item, i) => (
          <div className="mb-5 mx-0.5" key={`skill-${i}form`}>
            <div className={dynamicClassValues(type)?.label}>{`${item?.label}:`}</div>
            <div
              className={classNames(dynamicClassValues(type)?.value, enableTooltipForEmail(item) && 'overflow-hidden')}
            >
              <span className={classNames(enableTooltipForEmail(item) && 'truncate candidate-email')}>
                {item?.value || '--'}
              </span>
              {enableTooltipForEmail(item) && (
                <Tooltip
                  anchorSelect=".candidate-email"
                  className="absolute flex items-center justify-center p-1 rounded-md text-sm font-normal dark:text-white text-black dark:bg-neutral-700 bg-slate-300  z-50"
                  offset={2}
                >
                  {item?.value}
                </Tooltip>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

ViewFormData.propTypes = {
  formData: PropTypes.array,
  type: PropTypes.string,
};
export default ViewFormData;
