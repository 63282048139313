/* eslint-disable no-console */
/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import '../../global-style.css';
import React, { useEffect } from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { processToken } from 'utils/helpers';
import { useInjectReducer } from 'utils/injectReducer';
import growthbook from 'utils/growthbook';
import { makeSelectSaved } from 'containers/App/selectors';
import reducer from 'containers/App/reducer';
import TechnicalErrorPage from 'components/IR/TechnicalErrorPage';
import routes from './routes';

function App(props) {
  useInjectReducer({ key: 'App', reducer });
  const { state } = props;
  const { error } = state;
  const qp = new URLSearchParams(useLocation().search);
  const token = qp.get('token');

  useEffect(() => {
    growthbook.loadFeatures({ autoload: true });
    if (token) processToken(token);
  }, []);

  const routing = useRoutes(routes());

  return (
    <GrowthBookProvider growthbook={growthbook}>
      {error ? <TechnicalErrorPage error={error} /> : routing}
    </GrowthBookProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  state: makeSelectSaved(),
});
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
