export const FETCH_EVALUATION_DETAILS = 'app/containers/InterviewRooms/FETCH_EVALUATION_DETAILS';
export const FETCH_EVALUATION_DETAILS_COMPLETE = 'app/containers/InterviewRooms/FETCH_EVALUATION_DETAILS_COMPLETE';
export const EVALUATION_SAVE_ACTION = 'app/containers/InterviewRooms/EVALUATION_SAVE_ACTION';
export const EVALUATION_SAVE_ACTION_COMPLETE = 'app/EvaluationPage/SAVE_COMPLETE';
export const RATING_UPDATE_ACTION = 'app/EvaluationPage/RATING_UPDATE_ACTION';
export const RECOMMENDATION_ACTION = 'app/EvaluationPage/RECOMMENDATION_ACTION';
export const COMMENT_UPDATE_ACTION = 'app/EvaluationPage/COMMENT_UPDATE_ACTION';

export const REJECT = 'reject';
export const SELECT = 'select';
export const HOLD = 'hold';
