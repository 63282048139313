export const TOGGLE_PARTICIPANT_PANEL = 'app/containers/InterviewRooms/TOGGLE_PARTICIPANT_PANEL';
export const UPDATE_PARTICIPANT_STATUS = 'app/containers/InterviewRooms/UPDATE_PARTICIPANT_STATUS';
export const UPDATE_RECORDING_STATUS = 'app/containers/InterviewRooms/UPDATE_RECORDING_STATUS';
export const UPDATE_RECORDING_STATUS_COMPLETE = 'app/containers/InterviewRooms/UPDATE_RECORDING_STATUS_COMPLETE';
export const FETCH_FACE_AUTH_DETAILS = 'app/containers/InterviewRooms/FETCH_FACE_AUTH_DETAILS';
export const FETCH_FACE_AUTH_DETAILS_COMPLETE = 'app/containers/InterviewRooms/FETCH_FACE_AUTH_DETAILS_COMPLETE';
export const CREATE_CANDIDATE_PROFILE_COMPLETE = 'app/containers/InterviewRooms/CREATE_CANDIDATE_PROFILE_COMPLETE';
export const CREATE_AUTH_REQUEST = 'app/containers/InterviewRooms/CREATE_AUTH_REQUEST';
export const CREATE_CANDIDATE_PROFILE = 'app/containers/InterviewRooms/CREATE_CANDIDATE_PROFILE';
export const UPDATE_FULL_VIEW_TABS_OPEN_STATE = 'app/containers/InterviewRooms/UPDATE_FULL_VIEW_TABS_OPEN_STATE';
export const UPDATE_SIDE_TABS_OPEN_STATE = 'app/containers/InterviewRooms/UPDATE_SIDE_TABS_OPEN_STATE';
export const ADD_IR_EVENT_LOG = 'app/containers/InterviewRooms/ADD_IR_EVENT_LOG';
export const CLOSE_SUBSCRIPTION_CHANNELS = 'app/containers/InterviewRooms/CLOSE_SUBSCRIPTION_CHANNELS';
export const UPDATE_VIDEO_REQUEST_STATUS = 'app/containers/InterviewRooms/UPDATE_VIDEO_REQUEST_STATUS';
export const UPDATE_RESUME_FULLSCREEN_STATUS = 'app/containers/InterviewRooms/UPDATE_RESUME_FULLSCREEN_STATUS';
export const UPLOAD_FILE = 'app/containers/InterviewRooms/UPLOAD_FILE';
export const UPLOAD_FILE_COMPLETE = 'app/containers/InterviewRooms/UPLOAD_FILE_COMPLETE';
export const DOWNLOAD_FILE = 'app/containers/InterviewRooms/DOWNLOAD_FILE';
export const UPDATE_FILES_DOWNLOAD_STATE = 'app/containers/InterviewRooms/UPDATE_FILES_DOWNLOAD_STATE';

export const LEAVE_INTERVIEW_CONTENT_MESSAGE_MOBILE =
  'Others will continue after you leave, You can join the session again.';

export const ADD_CHAT_INPUT_VAL_TO_MODAL = 'app/containers/InterviewRooms/ADD_CHAT_TEXT_TO_MODAL';

export const UPDATE_MODALS_OPEN_STATE = 'app/containers/InterviewRooms/UPDATE_MODALS_OPEN_STATE';
export const VOIP_CALL_MODAL_STATE = 'app/containers/InterviewRooms/VOIP_MODAL_STATE';

export const GET_REGISTERED_USER = 'app/containers/InterviewRooms/GET_REGISTERED_USER_DATA';
export const GET_REGISTERED_USER_COMPLETED = 'app/containers/InterviewRooms/GET_REGISTERED_USER_DATA_COMPLETED';
export const INVITE_REGISTERED_USER = 'app/containers/InterviewRooms/INVITE_REGISTERED_USER';
export const INVITE_REGISTERED_USER_COMPLETED = 'app/containers/InterviewRooms/INVITE_REGISTERED_USER_COMPLETED';

export const SUBSCRIBE_PARTICIPANTS_DATA = 'app/containers/InterviewRooms/SUBSCRIBE_PARTICIPANTS_USER_DATA';
export const GET_VOIP_TOKEN = 'app/containers/InterviewRooms/GET_VOIP_TOKEN';
export const GET_VOIP_TOKEN_COMPLETED = 'app/containers/InterviewRooms/GET_VOIP_TOKEN_COMPLETED';
export const STORE_VOIP_CALL_DETAILS = 'app/containers/InterviewRooms/STORE_VOIP_CALL_DETAILS';
export const SEC_CAM_ENV_CHECK_REQUEST_TYPE = 'app/containers/InterviewRooms/SEC_CAM_ENV_CHECK_REQUEST_TYPE';

export const CANDIDATE_INMEETING_FACE_AUTH_INTERVAL = 600000;

export const CAMERA_DEVICE_LIST = [
  'Animaze Virtual Camera',
  'Casablanca Virtual Camera',
  'DemoCreator Virtual Camera',
  'EShare Virtual Camera',
  'Elgato Virtual Camera',
  'EƖgato Virtual Camera',
  'e2eSoft iVCam',
  'HitPaw Virtual Camera',
  'Intel Virtual Camera',
  'Intel(R) RealSense(TM) 3D Camera Virtual Driver',
  'ManyCam Virtual Webcam',
  'Mirametrix Virtual Camera',
  'OBS Virtual Camera',
  'OBS Virtual Camera (m-de:vice)',
  'OBSBOT Virtual Camera',
  'Prezi Video Virtual Camera',
  'SwapfaceCam',
  'ThinkVantage Virtual Camera',
  'Twitch Virtual Cam',
  'VMware Virtual Webcam',
  'VTuber Maker Virtual Camera',
  'VirtualBox Webcam - USB2.0 HD UVC WebCam (80ee:0030)',
  'VirtualBox Webcam - W100 (80ee:0030)',
  'VisioForge Virtual Camera',
  'WebPluginVirtualCamera',
  'vMix Video',
  'vMix Video External 2',
  'vMix Video External 2 YV12',
  'vMix Video YV12',
  'XSplit VCam',
  'XSplitBroadcaster',
];

export const ROOM_EVENT_NAMES = {
  PARTICIPANT_JOINED: 'PARTICIPANT_JOINED',
  PARTICIPANT_LEFT: 'PARTICIPANT_LEFT',
  DOMINANT_SPEAKER: 'DOMINANT_SPEAKER',
  VIDEO_START: 'VIDEO_START',
  VIDEO_STOP: 'VIDEO_STOP',
  AUDIO_START: 'AUDIO_START',
  AUDIO_STOP: 'AUDIO_STOP',
  SCREENSHARE_START: 'SCREENSHARE_START',
  SCREENSHARE_STOP: 'SCREENSHARE_STOP',
  RECORDING_START: 'RECORDING_START',
  RECORDING_STOP: 'RECORDING_STOP',
  ROOM_STARTED: 'ROOM_STARTED',
  ROOM_FINISHED: 'ROOM_FINISHED',
  TRACK_PUBLISHED: 'TRACK_PUBLISHED',
  TRACK_UNPUBLISHED: 'TRACK_UNPUBLISHED',
  EGRESS_STARTED: 'EGRESS_STARTED',
  EGRESS_UPDATED: 'EGRESS_UPDATED',
  EGRESS_ENDED: 'EGRESS_ENDED',
  POOR_VIDEO_QUALITY: 'POOR_VIDEO_QUALITY',
  FACE_NOT_DETECTED: 'FACE_NOT_DETECTED',
  MULTIPLE_FACES_DETECTED: 'MULTIPLE_FACES_DETECTED',
  MULTIPLE_AUDIO_DEVICES_DETECTED: 'MULTIPLE_AUDIO_DEVICES_DETECTED',
  POOR_NETWORK_QUALITY: 'POOR_NETWORK_QUALITY',
  TAB_SWITCH_DETECTED: 'TAB_SWITCH_DETECTED',
  WHITEBOARD_STARTED: 'WHITEBOARD_STARTED',
  WHITEBOARD_ENDED: 'WHITEBOARD_ENDED',
  CODEJAM_STARTED: 'CODEJAM_STARTED',
  CODEJAM_ENDED: 'CODEJAM_ENDED',
};

export const viewMap = {
  DEFAULT: 'normalView',
  COMPACT_VIEW: 'compactView',
  MINI_COMPACT_VIEW: 'miniCompactView',
};

export const statusMap = {
  SUCCESS: 'success',
  FAILED: 'failed',
  IN_PROGRESS: 'inProgress',
  CANCELLED: 'cancelled',
};

export const fileUploadErrorMap = {
  FILE_SIZE: 'File size more than 5Mb.',
  FILE_TYPE: 'File type not supported.',
  API_FAILED: 'File upload failed',
};

export const interviewRoomsTabs = {
  BOARD: 'board',
  CODE_JAM: 'codeJam',
  EVALUATION: 'evaluation',
  RESUME: 'resume',
};

export const effectsMap = {
  NONE: 'none',
  BLUR: 'blur',
  VIRTUAL: 'virtual',
};

export const browserPrefixes = ['moz', 'ms', 'o', 'webkit'];

export const chatLatestMessageState = {
  PUBLIC_MESSAGE: 'PUBLIC_MESSAGE',
  PRIVATE_MESSAGE: 'PRIVATE_MESSAGE',
  UNREAD_MESSAGES: 'UNREAD_MESSAGES',
  NO_UNREAD_MESSAGES: 'NO_UNREAD_MESSAGES',
};

export const INPUT_VALUES_TYPES = {
  CHAT_TEXT: 'chatText',
  CHAT_URL: 'chatUrl',
};

export const CHAT_LINK_INPUT_VALUES = {
  TEXT_INPUT: {
    id: `1-input`,
    label: 'Text',
    labelPosition: 'above',
  },
  URL_INPUT: {
    id: `2-input`,
    label: 'Link',
    labelPosition: 'above',
  },
};

export const CALL_BUTTON_TYPE = 'CallButton';

export const POPUP_TITLE = {
  CONFIRM_CALL: 'Confirm call',
  ADD_LINK: 'Add Link',
  INVITE_PEOPLE: 'Invite people to this interview',
  ACTION_REQUIRED: 'Action Required',
  LEAVE_INTERVIEW_WITHOUT_EVALUATION: 'You have not evaluated the candidate',
  LEAVE_INTERVIEW: 'Leave Interview',
  STOP_RECORDING: 'Are you sure you want to stop recording?',
  RESET_CODE: 'Are you sure want to reset?',
  CHANGE_LANGUAGE: 'Are you sure you want to change language?',
  CHOOSE_FROM_LIBRARY: 'Choose from library',
  EVALUATION_ERROR: 'Something went wrong',
  ROOM_DISCONNECTED: 'You have been disconnected',
};

export const POPUP_CONTENT = {
  CONFIRM_CALL: 'Are you sure you would like to call:',
  INVITE_PEOPLE: 'Invite existing team members or new ones.',
  LEAVE_INTERVIEW_WITHOUT_EVALUATION: 'Please take a moment to evaluate the candidate before leaving the interview.',
  LEAVE_INTERVIEW: 'Are you sure you want to leave the interview?',
  STOP_RECORDING: 'This will stop the recording and you will not be able to start recording again.',
  RESET_CODE:
    'This will reset the code to its initial state discarding all the changes you made. This action cannot be undone.',
  CHANGE_LANGUAGE: 'This will save your current code and reset to new language. you can always go back to it.',
  EVALUATION_ERROR: 'Please try again. If the problem continues, refresh the page and try once more.',
  ROOM_DISCONNECTED: 'You may want to check your internet connection.',
};

export const POPUP_SUCCESS_BUTTON = {
  CONFIRM_CALL: 'Yes, call',
  SAVE: 'Save',
  ACCEPT: 'Accept',
  EVALUATE_NOW: 'Evaluate Now',
  CONFIRM: 'Confirm',
  STOP_RECORDING: 'Stop recording',
  YES: 'Yes',
  OK: 'Ok',
  REJOIN: 'Rejoin',
};

export const POPUP_CANCEL_BUTTON = {
  CANCEL: 'Cancel',
  DENY: 'Deny',
  LATER: 'Later',
  NO: 'No',
};

export const POPUP_MODAL_TYPE = {
  CONFIRM_CALL: 'confirm_call',
  CHAT_LINK: 'chat_link',
  INVITE_PEOPLE: 'invite_people',
  INTERVIEW_ROOMS: 'interview_rooms',
  SEC_CAM_360_ENV_CHECK: 'sec_cam_360_env_check',
  ROOM_DISCONNECTED: 'room_disconnect',
};

export const VOIP_MODAL_CONTENT = {
  LOADING_DESC: 'Initiating call',
  ERROR_TITLE: 'Communication Error',
  ERROR_DESC: 'Oops, we ran into a problem. Please try again later or',
  ERROR_BTN: 'Call again',
  CONNECTED: 'Connected',
  CALLING: 'Calling...',
};

export const BUTTON_CONTENT = {
  CALL: 'Call',
};

export const TOOLTIP_CONTENT = {
  CALL_IN_PROGRESS: 'Call in progress',
};

export const SECONDARY_CAM_ENV_CHECK_REQUEST = 'Request';
export const SECONDARY_CAMERA = 'Secondary Camera';

export const IR_CHAT = 'chat';

export const SUPPORT_LINK = 'https://talview.freshdesk.com/support/tickets/news';
