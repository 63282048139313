export const INSIGHTS_INIT = 'app/containers/InterviewInsights/INSIGHTS_INIT';
export const INSIGHTS_INIT_COMPLETE = 'app/containers/InterviewInsights/INSIGHTS_INIT_COMPLETE';
export const INSIGHTS_FETCH_MEETING_DETAILS = 'app/containers/InterviewInsights/INSIGHTS_FETCH_MEETING_DETAILS';
export const INSIGHTS_FETCH_MEETING_DETAILS_COMPLETE =
  'app/containers/InterviewInsights/INSIGHTS_FETCH_MEETING_DETAILS_COMPLETE';
export const GET_RECORDING_VIDEO_COMPLETE = 'app/containers/InterviewInsights/GET_RECORDING_VIDEO_COMPLETE';
export const INSIGHTS_FETCH_RESUME_URL_COMPLETE = 'app/containers/InterviewInsights/INSIGHTS_FETCH_RESUME_URL_COMPLETE';
export const INSIGHTS_GET_CANDIDATE_DETAILS_COMPLETE =
  'app/containers/InterviewInsights/INSIGHTS_GET_CANDIDATE_DETAILS_COMPLETE';
export const INSIGHTS_FETCH_FACE_AUTH_DETAILS = 'app/containers/InterviewInsights/INSIGHTS_FETCH_FACE_AUTH_DETAILS';
export const FETCH_TRANSCRIPT_DETAILS = 'app/containers/InterviewInsights/FETCH_TRANSCRIPT_DETAILS';
export const FETCH_TRANSCRIPT_DETAILS_COMPLETE = 'app/containers/InterviewInsights/FETCH_TRANSCRIPT_DETAILS_COMPLETE';
export const INSIGHTS_FETCH_FACE_AUTH_DETAILS_COMPLETE =
  'app/containers/InterviewInsights/INSIGHTS_FETCH_FACE_AUTH_DETAILS_COMPLETE';
export const FETCH_INTERVIEW_INSIGHTS_DETAILS_COMPLETE =
  'app/containers/InterviewInsights/FETCH_INTERVIEW_INSIGHTS_DETAILS_COMPLETE';
export const STORE_CODEJAM_DATA = 'app/containers/InterviewInsights/STORE_CODEJAM_DATA';
export const STORE_PUBLIC_CHAT_DATA = 'app/containers/InterviewInsights/STORE_PUBLIC_CHAT_DATA';
export const STORE_PRIVATE_CHAT_DATA = 'app/containers/InterviewInsights/STORE_PRIVATE_CHAT_DATA';
export const INSIGHTS_TABS_OPEN_STATE = 'app/containers/InterviewInsights/INSIGHTS_TABS_OPEN_STATE';
export const CHAT_ACTIVE_TAB = 'app/containers/InterviewInsights/CHAT_ACTIVE_TAB';

export const INSIGHTS = 'insights';
export const INSIGHTS_VERIFICATION = 'insightsVerification';
export const CANDIDATE_OVERVIEW = 'candidate_overview';
export const INSIGHTS_TIMELINE = 'insights_timeline';
export const INSIGHTS_CHAT = 'insights_chat';

export const FIREBASE_COLLECTION_NAME = {
  IR_CHAT: 'IR_CHAT',
  PUBLIC_CHAT: 'ir_public_chat',
  PRIVATE_CHAT: 'ir_private_chat',
  CODE_JAM: 'ir_codejam',
};
