import React from 'react';
import EvaluationSlider from 'components/IR/EvaluationSlider';
import { get, find, map } from 'lodash';

export function EvaluationParameters({ evaluation, parameters, assessment_section, updateRating, rating }) {
  return (
    <div className="overflow-scroll no-scrollbar">
      {get(assessment_section, 'section.section_parameters', []).length && (
        <div className="flex flex-col items-center overflow-y-auto no-scrollbar">
          {map(parameters, (grouped, index) => (
            <div key={index} className="mb-10 w-full">
              <div className="flex justify-between dark:bg-neutral-900 bg-blue-50 px-4 py-2">
                <h4 className="font-medium text-sm dark:text-neutral-200 text-gray-900">
                  {get(grouped, '0.parameter.category.name', 'Parameters')}
                </h4>
                <p className="dark:text-neutral-400 text-gray-500 text-xs hidden">
                  Average score <span className="dark:text-neutral-50 text-gray-900">--</span>
                </p>
              </div>
              <div className="py-4 px-[2.375rem] flex flex-wrap gap-x-14 gap-y-6">
                {map(grouped, sp => (
                  <div className="w-[16.625rem] flex justify-between items-center m-1.5" key={get(sp, 'parameter.id')}>
                    <EvaluationSlider
                      title={get(sp, 'parameter.name')}
                      min={0}
                      setValue={data => {
                        updateRating({
                          ...rating,
                          ...{
                            [get(sp, 'parameter.id')]: {
                              parameter_id: get(sp, 'parameter.id'),
                              rating: parseInt(data, 10),
                            },
                          },
                        });
                      }}
                      max={parseInt(
                        get(
                          rating,
                          `${get(sp, 'parameter.id')}.rating`,
                          get(
                            find(get(evaluation, `0.evaluationRatings`, []), {
                              parameter_id: get(sp, 'parameter.id'),
                            }),
                            'rating',
                            0,
                          ),
                        ),
                        10,
                      )}
                      value={rating[get(sp, 'parameter.id')]?.rating}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default EvaluationParameters;
