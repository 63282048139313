/* eslint-disable no-console */
export const log = (...args) => {
  const msg = args[0];
  console.log(msg, ...args.slice(1));
};

export const err = (...args) => {
  const msg = args[0];
  console.error(msg, ...args.slice(1));
};

export const warn = (...args) => {
  const msg = args[0];
  console.warn(msg, ...args.slice(1));
};
