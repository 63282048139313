import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

export const selectedParticipantContext = createContext(null);

export default function useSelectedParticipant() {
  const [selectedParticipant, setSelectedParticipant] = useContext(selectedParticipantContext);
  return [selectedParticipant, setSelectedParticipant];
}

export function SelectedParticipantProvider({ room, children }) {
  const [selectedParticipant, _setSelectedParticipant] = useState(null);
  const setSelectedParticipant = participant =>
    _setSelectedParticipant(prevParticipant => (prevParticipant === participant ? null : participant));

  useEffect(() => {
    const onDisconnect = () => _setSelectedParticipant(null);
    const handleParticipantDisconnected = participant =>
      _setSelectedParticipant(prevParticipant => (prevParticipant === participant ? null : prevParticipant));
    if (room) {
      room.on('disconnected', onDisconnect);
      room.on('participantDisconnected', handleParticipantDisconnected);
    }

    return () => {
      if (room) {
        room.off('disconnected', onDisconnect);
        room.off('participantDisconnected', handleParticipantDisconnected);
      }
    };
  }, [room]);
  const value = useMemo(
    () => [selectedParticipant, setSelectedParticipant],
    [selectedParticipant, setSelectedParticipant],
  );
  return <selectedParticipantContext.Provider value={value}>{children}</selectedParticipantContext.Provider>;
}
