const env_map = {
  development: {
    FIREBASE_API_KEY: process.env.FIREBASE_API_KEY_DEV,
    FIREBASE_API_DOMAIN: 'top-dev-356710.firebaseapp.com',
    FIREBASE_PROJECT_ID: 'top-dev-356710',
    HASURA_HTTP: 'https://top-api.talview.org/v1/graphql',
    HASURA_WS: 'wss://top-api.talview.org/v1/graphql',
    LIVEKIT_URL: 'wss://ir-video-dev-v2y7lzwb.livekit.cloud',
    ENV: 'development',
    GRAPH_API_HTTP: process.env.GRAPH_API_HTTP,
    GRAPH_API_WS: process.env.GRAPH_API_WS,
    REACT_APP_AUTH_URL: 'https://admin.talview.org',
    LIVEKIT_SELF_HOSTED_URL: 'wss://livekit.talview.org',
  },
  production: {
    FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
    FIREBASE_API_DOMAIN: process.env.FIREBASE_API_DOMAIN,
    FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
    HASURA_HTTP: process.env.HASURA_HTTP,
    HASURA_WS: process.env.HASURA_WS,
    LIVEKIT_URL: process.env.LIVEKIT_URL,
    ENV: 'production',
    GRAPH_API_HTTP: process.env.GRAPH_API_HTTP,
    GRAPH_API_WS: process.env.GRAPH_API_WS,
    REACT_APP_AUTH_URL: 'https://login.talview.com',
    LIVEKIT_SELF_HOSTED_URL: process.env.LIVEKIT_SELF_HOSTED_URL,
  },
};

class EnvConfig {
  constructor() {
    if (!EnvConfig.instance) {
      EnvConfig.instance = this;
    }
  }

  get envObj() {
    return this._envObj;
  }

  fetchEnvObj() {
    try {
      if (this._envObj) return this._envObj;
      const urlParams = new URLSearchParams(window.location.search);
      const isDevelop = urlParams.get('env');
      if (isDevelop && env_map[isDevelop]) {
        this._envObj = env_map[isDevelop];
      } else {
        this._envObj = env_map?.production;
      }
      return this._envObj;
    } catch (e) {
      this._envObj = env_map?.production;
      return this._envObj;
    }
  }

  clearEnvObj() {
    if (this._envObj) {
      this._envObj = null;
      return true;
    }
    return false;
  }
}

const instance = new EnvConfig();

export default instance;
