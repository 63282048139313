import { useEffect } from 'react';

export default function useHandleTrackPublicationFailed(room = null, onError = () => {}) {
  useEffect(() => {
    if (room) {
      room.localParticipant.on('trackPublicationFailed', onError);
    }
    return () => {
      if (room) {
        room.localParticipant.off('trackPublicationFailed', onError);
      }
    };
  }, [room, onError]);
}
