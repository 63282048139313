/**
 *
 * EvaluationButtonStrip
 *
 */

import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from 'components/IR/Button';
import { HOLD, REJECT, SELECT } from 'containers/Evaluation/constants';

function EvaluationButtonStrip(props) {
  const { recommendation, saveLoading, save, evaluated } = props;

  return (
    <div className="flex justify-center items-center gap-4 flex-wrap">
      <Button
        isLoading={recommendation === SELECT && saveLoading === true}
        buttonClassname={classNames(
          'w-24 h-9 border-2 border-green-500 rounded-lg font-semibold text-green-500',
          evaluated && 'disabled cursor-not-allowed',
        )}
        onClick={() => {
          if (!evaluated) save(SELECT);
        }}
        data-testid="Select"
      >
        Select
      </Button>

      <Button
        isLoading={recommendation === HOLD && saveLoading === true}
        buttonClassname={classNames(
          'w-24 h-9 border-2 border-yellow-400 rounded-lg font-semibold text-yellow-400',
          evaluated && 'disabled cursor-not-allowed',
        )}
        onClick={() => {
          if (!evaluated) save(HOLD);
        }}
        data-testid="Hold"
      >
        Hold
      </Button>

      <Button
        isLoading={recommendation === REJECT && saveLoading === true}
        buttonClassname={classNames(
          'w-24 h-9 border-2 border-red-500 rounded-lg font-semibold text-red-500',
          evaluated && 'disabled cursor-not-allowed',
        )}
        onClick={() => {
          if (!evaluated) save(REJECT);
        }}
        data-testid="Reject"
      >
        Reject
      </Button>
    </div>
  );
}

EvaluationButtonStrip.propTypes = {
  save: PropTypes.func,
  recommendation: PropTypes.string,
  saveLoading: PropTypes.bool,
  evaluated: PropTypes.bool,
};

export default memo(EvaluationButtonStrip);
