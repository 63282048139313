/* eslint-disable no-console */
import { ApolloClient, InMemoryCache, split, createHttpLink, ApolloLink, from } from '@apollo/client';
import { WebSocketLink } from 'apollo-link-ws';
import { Observable } from 'apollo-link';
import * as Sentry from '@sentry/react';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist'; // todo make async
import { onError } from 'apollo-link-error';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import localforage from 'localforage';
import { get } from 'lodash';
import { reAuthenticateUser } from 'utils/firebase';
import EnvConfig from 'utils/envConfig';
import { getTraceId } from 'utils/helpers';

class Client {
  constructor() {
    if (!Client.instance) {
      Client.instance = this;
    }
  }

  get client() {
    return this._client;
  }

  get recruit_client() {
    return this._recruit_client;
  }

  get unauthenticated_client() {
    return this._unauthenticated_client;
  }

  get persistor() {
    return this._persistor;
  }

  get trace_id() {
    return this._trace_id;
  }

  geterrorLink() {
    // eslint-disable-next-line consistent-return
    return onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        const err = get(graphQLErrors, '0');
        if (err) {
          const code = get(err, 'extensions.code');
          switch (code) {
            case 'invalid-jwt':
              return new Observable(observer => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };
                const context = operation?.getContext();
                const api_url = context?.response?.url;
                reAuthenticateUser(api_url).then(token => {
                  if (token) {
                    if (token?.id_token) {
                      this._client = null;
                      if (this._wssClient?.close) this._wssClient?.close();
                    } else if (token?.access_token) {
                      this._recruit_client = null;
                      if (this._recruitWssClient?.close) this._recruitWssClient?.close();
                    }
                    const oldHeaders = context.headers;
                    operation.setContext({
                      headers: {
                        ...oldHeaders,
                        authorization: `Bearer ${token?.id_token || token?.access_token}`,
                      },
                    });
                  }
                  forward(operation).subscribe(subscriber);
                });
              });
            default:
              if (console && err.message) {
                console.warn(`[GraphQL error]: Message: ${err.message}, Location: ${err.extensions.code}`);
                return new Observable(observer => {
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
                  forward(operation).subscribe(subscriber);
                });
              }
          }
        }
      }
      if (networkError && console) {
        console.error(`[Network error]: ${networkError}`);
        return forward(operation);
      }
    });
  }

  getHttpLink(url, headers) {
    return createHttpLink({
      uri: `${url}`,
      headers,
    });
  }

  getSubsciptionClient(wss_url, headers) {
    return new SubscriptionClient(wss_url, {
      reconnect: true,
      connectionParams: {
        headers,
      },
      connectionCallback: error => {
        console.log(error);
      },
    });
  }

  getLinkObj(graphToken, role) {
    const headers = {};
    if (graphToken) {
      headers.Authorization = `Bearer ${graphToken}`;
    }
    if (role) {
      headers['x-hasura-role'] = role;
    }
    const env_config = EnvConfig.fetchEnvObj();
    const httpLink = this.getHttpLink(env_config.HASURA_HTTP, headers);
    this._wssClient = this.getSubsciptionClient(env_config.HASURA_WS, headers);
    const wsLink = new WebSocketLink(this._wssClient);
    const link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      httpLink,
    );
    return link;
  }

  traceLink() {
    return new ApolloLink((operation, forward) => {
      try {
        const traceId = getTraceId();
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            'x-hasura-trace-id': traceId,
          },
        }));
        Sentry.getCurrentScope().setTag('trace-id', traceId);
      } catch (e) {
        console.log('Trace Id generation failed');
      }
      return new Observable(observer => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };
        forward(operation).subscribe(subscriber);
      });
    });
  }

  async createClient({ graphToken, role }) {
    if (this._client) return this._client;
    const link = this.getLinkObj(graphToken, role);
    const cache = new InMemoryCache();
    this._persistor = new CachePersistor({
      cache,
      maxSize: false,
      debounce: 500,
      storage: new LocalForageWrapper(localforage),
    });
    await localforage.setDriver([localforage.INDEXEDDB, localforage.LOCALSTORAGE]);
    this._client = new ApolloClient({
      link: from([this.geterrorLink(), this.traceLink(), link]),
      cache,
    });
    return this._client;
  }

  async createNoAuthClient() {
    if (this._unauthenticated_client) return this._unauthenticated_client;
    const headers = {};
    const env_config = EnvConfig.fetchEnvObj();
    const httpLink = this.getHttpLink(env_config.HASURA_HTTP, headers);
    const wssClient = this.getSubsciptionClient(env_config.HASURA_WS, headers);
    const wsLink = new WebSocketLink(wssClient);
    const link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      httpLink,
    );
    const cache = new InMemoryCache();
    this._unauthenticated_client = new ApolloClient({
      link: from([this.geterrorLink(), this.traceLink(), link]),
      cache,
    });
    return this._unauthenticated_client;
  }

  async createRecruitClient({ graphToken }) {
    if (this._recruit_client) return this._recruit_client;
    const headers = {};
    if (graphToken) {
      headers.Authorization = `Bearer ${graphToken}`;
    }
    const env_config = EnvConfig.fetchEnvObj();
    const httpLink = this.getHttpLink(env_config.GRAPH_API_HTTP, headers);
    this._recruitWssClient = this.getSubsciptionClient(env_config.GRAPH_API_WS, headers);
    const wsLink = new WebSocketLink(this._recruitWssClient);
    const link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      httpLink,
    );
    const cache = new InMemoryCache();
    this._recruit_client = new ApolloClient({
      link: this.geterrorLink().concat(link),
      cache,
    });
    return this._recruit_client;
  }

  async clearCache() {
    if (this._persistor) {
      return this._persistor.purge();
    }
    return Promise.resolve();
  }

  removeClient() {
    if (this._client) {
      this._client.stop();
      return this.client.clearStore();
    }
    if (this._recruit_client) {
      this._recruit_client.stop();
      return this._recruit_client.clearStore();
    }
    if (this.__unauthenticated_client) {
      this.__unauthenticated_client.stop();
      return this.__unauthenticated_client.clearStore();
    }
    return true;
  }
}
const instance = new Client();

export default instance;
