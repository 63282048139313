import SubmitBlade from 'images/Feedback/SubmitBlade.svg';
import SubmitTriangle from 'images/Feedback/SubmitTriangle.svg';
import SubmitSquare from 'images/Feedback/SubmitSquare.svg';
import SubmitStar from 'images/Feedback/SubmitStar.svg';

export const submitImages = { SubmitBlade, SubmitTriangle, SubmitSquare, SubmitStar };
export const FEEDBACK_RATING = {
  1: 'Oh no!',
  2: 'Not great!',
  3: 'Just okay!',
  4: 'Lovely!',
  5: 'Awesome!',
};
export const FEEDBACK = 'feedback';
export const WEB = 'web';
export const MOBILE = 'mobile';
export const CLEAR = 'clear';
export const PLEASE_SPECIFY = 'Please specify...';
export const OTHERS_PLEASE_SPECIFY = 'Others, please specify...';
export const SVG_ICON_SIZE = {
  medium: '36',
  large: '48',
};

export const FEEDBACK_TYPES = {
  candidate: 'ir.Candidate',
  interviewer: 'ir.Interviewer',
};

export const ratingButtons = [
  { rating: 1, color: '#ef4444' },
  { rating: 2, color: '#fb923c' },
  { rating: 3, color: '#eab308' },
  { rating: 4, color: '#84cc16' },
  { rating: 5, color: '#22c55e' },
];

const commonVideoIssues = [
  "I couldn't see other participants",
  "Others couldn't see my video",
  'Video was blurry',
  'The video kept freezing',
  'The video stopped unexpectedly',
  'The other video was too dark',
  'The video was out of sync with the audio',
  'The participant didn’t share video',
];

const commonAudioIssues = [
  "I couldn't hear any audio",
  'Other participants could not hear me',
  'I heard echo in the call',
  'I heard background noise',
  'I experienced lag in the audio',
  'The volume was too low',
];

const commonOtherIssues = [
  'The session took too long to connect',
  'I had trouble joining the call',
  'I was unable to mute or unmute myself',
  'Call ended unexpectedly',
];

export const INTERVIEWER_FEEDBACK_ISSUES_LIST = {
  web: {
    Video: [...commonVideoIssues],
    Audio: [...commonAudioIssues],
    Others: [
      ...commonOtherIssues,
      'I had problems during coding session',
      'I had problems during screen sharing',
      'I had problems during evaluation',
      'I had problems when using the guide',
      'I had problems during whiteboarding session',
      'Other participant was unable to mute or unmute',
    ],
  },
  mobile: {
    Video: [...commonVideoIssues],
    Audio: [...commonAudioIssues],
    Others: [...commonOtherIssues, 'Other participant was unable to mute or unmute'],
  },
};

export const CANDIDATE_FEEDBACK_ISSUES_LIST = {
  web: {
    Video: [...commonVideoIssues],
    Audio: [...commonAudioIssues],
    Others: [
      ...commonOtherIssues,
      'I had problems during coding session',
      'I had problems during screen sharing',
      'I had problems during whiteboarding session',
      'Other participant was unable to mute or unmute',
    ],
  },
  mobile: {
    Video: [...commonVideoIssues],
    Audio: [...commonAudioIssues],
    Others: [...commonOtherIssues, 'Other participant was unable to mute or unmute'],
  },
};
