import {
  FETCH_EVALUATION_DETAILS,
  EVALUATION_SAVE_ACTION,
  RATING_UPDATE_ACTION,
  RECOMMENDATION_ACTION,
  COMMENT_UPDATE_ACTION,
} from 'containers/Evaluation/constants';

export function fetchEvaluationDetails(id) {
  return {
    type: FETCH_EVALUATION_DETAILS,
    id,
  };
}

export function saveAction({ recommendation, formData, files, onClose }) {
  return {
    type: EVALUATION_SAVE_ACTION,
    recommendationOption: recommendation,
    formData,
    files,
    onClose,
  };
}

export function updateRatingAction(rating) {
  return {
    type: RATING_UPDATE_ACTION,
    rating,
  };
}

export function updateRecommendationAction(recommendation) {
  return {
    type: RECOMMENDATION_ACTION,
    recommendation,
  };
}

export function updateCommentAction(evaluationComment) {
  return {
    type: COMMENT_UPDATE_ACTION,
    evaluationComment,
  };
}
