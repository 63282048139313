import { useEffect } from 'react';

export default function useHandleRoomDisconnection(
  room = null,
  onError = () => {},
  isSharingScreen = false,
  toggleScreenShare = () => {},
) {
  useEffect(() => {
    const onDisconnected = (_, error) => {
      if (error) {
        onError(error);
      }

      if (isSharingScreen) {
        toggleScreenShare();
      }
    };
    if (room) {
      room.on('disconnected', onDisconnected);
    }
    return () => {
      if (room) room.off('disconnected', onDisconnected);
    };
  }, [room, onError, isSharingScreen, toggleScreenShare]);
}
