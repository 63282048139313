import React from 'react';
import LeavePage from 'images/ParticipantEndPage.svg';
import LeavePageMobile from 'images/LeavePageMobile.svg';

function ParticipantLeavePage() {
  return (
    <div className="w-screen h-screen flex items-center justify-center dark:bg-neutral-900 bg-slate-50  sm:bg-white sm:p-20">
      <div className="flex flex-col items-center justify-center">
        <img src={LeavePageMobile} alt="Leave Page" className="flex text-center sm:hidden" />
        <h1 className="dark:text-white text-gray-800 sm:dark:text-blue-600 sm:text-blue-600 text-2xl sm:text-3xl font-bold text-center">
          The interview has ended
        </h1>
        <p className="dark:text-neutral-200 text-gray-800  flex sm:hidden text-center">Thanks for joining</p>
        <div className="flex gap-4 mt-3">
          <p className="text-medium dark:text-neutral-500 text-slate-500  font-medium">You can close this tab now</p>
        </div>
        <img src={LeavePage} alt="Svg" className="mt-4 hidden sm:flex" />
      </div>
    </div>
  );
}

export default ParticipantLeavePage;
