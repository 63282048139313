export const classnamesConfigMap = {
  chat: {
    header: 'rounded-lg border-2 dark:border-neutral-700 border-slate-300 px-4',
    indicatorProps: 'border-b-2 border-blue-600 mx-1',
    tabs: 'font-medium p-2 text-sm chat-tab -mb-0.5',
  },
  default: {
    header:
      'gap-10 border-b border-t-0 border-l-0 border-r-0 dark:border-neutral-700 border-slate-300 rounded-none pl-10',
    indicatorProps: 'border-b dark:border-white border-black mx-1',
    tabs: 'font-semibold text-sm p-0 pb-2',
  },
  insights: {
    header: 'gap-10 border-b-2 border-t-0 border-l-0 border-r-0 border-slate-300 rounded-none',
    indicatorProps: 'border-b-2 border-blue-600 mx-1',
    tabs: 'font-semibold text-sm p-0 pb-2 -mb-0.5',
  },
  insights_timeline: {
    header: 'gap-10 border-b-2 border-t-0 border-l-0 border-r-0 border-slate-300 rounded-none p-0',
    indicatorProps: 'border-b-2 border-blue-600',
    tabs: 'font-medium text-base p-0 pb-2 -mb-0.5 w-28',
  },
  insights_chat: {
    header: 'rounded-lg border-2 dark:border-neutral-700 border-slate-300 px-1',
    indicatorProps: 'border-b-2 border-blue-600 mx-1',
    tabs: 'px-2 text-xs chat-tab -mb-0.5',
  },
};
