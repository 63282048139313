import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export function EvaluationSlider({ title, setValue, value }) {
  const [sliderValue, setSliderValue] = React.useState(value);

  useEffect(() => {
    setValue(sliderValue);
  }, [sliderValue]);

  return (
    <div className="dark:text-white text-sm overflow-scroll no-scrollbar w-full">
      <p className=" font-normal dark:text-neutral-50 text-gray-900">{title}</p>
      <div className="flex justify-between mb-1">
        <p className="dark:text-neutral-400 text-gray-500">Threshold:2</p>
        <p className="dark:text-neutral-300 text-gray-900 -ml-1 flex justify-center items-center">
          <span className="border dark:border-neutral-700 border-gray-200 px-2 rounded mr-1">{sliderValue}</span>
          /10
        </p>
      </div>
      <input
        type="range"
        min="1"
        max="10"
        value={sliderValue}
        step="1"
        className="h-4 w-full cursor-pointer"
        id="myRange"
        onChange={event => setSliderValue(event.target.value)}
      />
    </div>
  );
}

EvaluationSlider.propTypes = {
  title: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.number,
};

EvaluationSlider.defaultProps = {
  value: 0,
};

export default EvaluationSlider;
