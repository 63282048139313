import { useState, useEffect } from 'react';

export default function useIsTrackEnabled(track) {
  const [isEnabled, setIsEnabled] = useState(
    // eslint-disable-next-line no-nested-ternary
    track ? (Object.hasOwn(track, 'isEnabled') ? track.isEnabled : !track.isMuted) : false,
  );
  useEffect(() => {
    if (track && Object.hasOwn(track, 'isEnabled')) {
      setIsEnabled(track ? track.isEnabled : false);
    } else {
      setIsEnabled(track ? !track.isMuted : false);
    }

    const setEnabled = () => setIsEnabled(true);
    const setDisabled = () => setIsEnabled(false);
    if (track) {
      track.on('enabled', setEnabled);
      track.on('disabled', setDisabled);
      track.on('muted', setDisabled);
      track.on('unmuted', setEnabled);
    }
    return () => {
      if (track) {
        track.off('enabled', setEnabled);
        track.off('disabled', setDisabled);
        track.off('muted', setDisabled);
        track.off('unmuted', setEnabled);
      }
    };
  }, [track]);

  return isEnabled;
}
