/* eslint-disable no-param-reassign */

import * as Sentry from '@sentry/react';
import { err } from 'utils/logger';
import { get } from 'lodash';
import { getWindowObject } from 'containers/InterviewRooms/utils';
import { version } from '../version';

export const beforeSend = (event, hint) => {
  const qp = new URLSearchParams(window.location.search);
  const meeting_details = getWindowObject('meeting_details') || {};
  const meeting_id = get(meeting_details, 'meeting_id') || qp.get('meeting_id') || 'unknown';
  const participant_id = get(meeting_details, 'participant_id') || 'unknown';
  const tenant_id = get(meeting_details, 'tenant_id') || 'unknown';
  if (process.env.NODE_ENV === 'local') {
    err('Error logged by sentry: ', hint.originalException || hint.syntheticException);
    return null;
  }
  event.tags = event.tags || {};
  event.tags.live_session_id = meeting_id;
  event.tags.org_id = tenant_id;
  event.tags.live_attendee_id = participant_id;
  return event;
};

export default function initSentry() {
  // eslint-disable-next-line no-console
  console.log(`Version: ${version}`);
  Sentry.init({
    dsn: 'https://36c9e945fe5874fd5f4be1a04a9de3b3@sentry.talview.org/17',
    // sample sessions when an error occurs.
    replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0,
    replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [window.location.origin, process.env.HASURA_HTTP, process.env.GRAPH_API_HTTP],
        networkCaptureBodies: true,
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
    ],
    release: version || 'dev',
    environment: process.env.NODE_ENV || 'development',
    enabled: true,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0.5,
    beforeSend,
  });
}
