import React, { memo, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { XMarkIcon } from '@heroicons/react/24/outline';
import GenericButton from 'components/GenericButton';
import { classnamesConfigMap } from 'components/PopupNew/configMap';
import { POPUP_MODAL_TYPE } from 'containers/InterviewRooms/constants';

function PopupNew(props) {
  const {
    type,
    title,
    children,
    onClose,
    isOpen,
    hasCancel,
    cancelClick,
    content,
    success,
    cancel,
    showCheckBox,
    showModalAgain,
    cancelBtnStyles,
    confirmBtnStyles,
    isSuccessDisabled,
    headerIcon,
    noButton,
    titleLeadingIcon,
    noXMarkIcon,
  } = props;
  const [isChecked, setChecked] = useState(false);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames('exclude fixed inset-0 overflow-y-auto', classnamesConfigMap[type || 'default']?.dialog)}
        onClose={type === POPUP_MODAL_TYPE.CHAT_LINK ? cancelClick : () => {}}
      >
        <div className="flex items-center justify-center min-h-screen align-middle w-screen h-screen ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                'shadow-[0rem_-0.125rem_1.563rem_rgba(79,70,229,0.12)] rounded-md',
                'dark:shadow-[0rem_-0.125rem_1.563rem_rgba(0,0,0,0.3)] transform transition-all',
                'w-10/12 sm:w-[32rem] border-2 p-6 bg-slate-50',
                classnamesConfigMap[type || 'default']?.dialogTitleParent,
              )}
            >
              <div className={classNames('bg-slate-50', classnamesConfigMap[type || 'default']?.dialogTitleParentBg)}>
                <div
                  className={classNames(
                    'w-full h-full',
                    classnamesConfigMap[type || 'default']?.dialogTitleParentChild,
                  )}
                >
                  {title && (
                    <Dialog.Title className="text-lg leading-6 font-medium" as="div">
                      <div className={classNames(classnamesConfigMap[type || 'default']?.dialogTitle)}>
                        {!noXMarkIcon && (
                          <span
                            className={classNames(
                              'absolute right-6 cursor-pointer hidden sm:flex',
                              classnamesConfigMap[type || 'default']?.xMarkPosition,
                            )}
                          >
                            <XMarkIcon
                              className="dark:text-neutral-400 text-slate-400 w-6 h-6 cursor-pointer dark:hover:text-neutral-50 hover:text-slate-800"
                              onClick={() => cancelClick('noExit')}
                              data-testid="cross-icon"
                            />
                          </span>
                        )}
                        {headerIcon && <FontAwesomeIcon icon={headerIcon} className="text-blue-600 w-11 h-9 mb-3" />}
                        <div
                          className={classNames('flex items-center', classnamesConfigMap[type]?.dialogTitleH3Parent)}
                        >
                          {titleLeadingIcon && (
                            <FontAwesomeIcon
                              icon={titleLeadingIcon}
                              className="dark:text-white text-gray-900 w-6 h-6 mr-3"
                            />
                          )}
                          <h3
                            className={classNames(
                              'dark:text-white text-gray-900',
                              classnamesConfigMap[type || 'default']?.dialogTitleH3,
                            )}
                          >
                            {title}
                          </h3>
                        </div>

                        <h3 className={classNames(classnamesConfigMap[type || 'default']?.dialogTitleH3Content)}>
                          {content}
                        </h3>
                      </div>
                    </Dialog.Title>
                  )}
                  <div
                    className={classNames('w-full', classnamesConfigMap[type || 'default']?.childrenClass)}
                    style={{ color: '#fff' }}
                  >
                    {children}
                  </div>
                </div>
              </div>
              {!noButton && (
                <div
                  className={classNames(
                    'bg-slate-50 flex items-center mt-[0.875rem] text-base leading-6 font-semibold',
                    showCheckBox ? 'justify-between' : 'justify-end',
                    classnamesConfigMap[type || 'default']?.checkBoxAndButtonParent,
                  )}
                >
                  {showCheckBox && (
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        className="min-w-[1.14rem] w-[1.14rem] min-h-[1.14rem] h-[1.14rem] border-2 border-radius-[0.325rem] dark:border-neutral-400 border-slate-400 outline-none"
                        id="checkbox"
                        onClick={() => setChecked(!isChecked)}
                      />
                      <label
                        className="text-slate-400 dark:text-neutral-400 text-sm ml-2 font-normal"
                        htmlFor="checkbox"
                      >{`Don't ask again`}</label>
                    </div>
                  )}

                  <div className={classNames('flex', classnamesConfigMap[type]?.buttonParent)}>
                    {hasCancel && (
                      <GenericButton
                        onClick={cancelClick}
                        classNames={
                          cancelBtnStyles ||
                          'w-14 h-10 border border-gray-300 dark:border-gray-700 text-gray-700 dark:text-slate-50'
                        }
                      >
                        {cancel}
                      </GenericButton>
                    )}
                    <GenericButton
                      onClick={() => {
                        onClose();
                        if (isChecked) showModalAgain(false);
                      }}
                      classNames={confirmBtnStyles || 'w-14 h-10 text-white ml-3 dark:bg-indigo-600 bg-indigo-500'}
                      disabled={isSuccessDisabled}
                      data-testid="popupnew_close_btn"
                    >
                      {success}
                    </GenericButton>
                  </div>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

PopupNew.propTypes = {
  title: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  hasCancel: PropTypes.bool,
  type: PropTypes.string,
};
PopupNew.defaultProps = {
  hasCancel: true,
  isOpen: false,
};

export default memo(PopupNew);
