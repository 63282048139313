export const TRACKS_ACQUIRING_ERROR = {
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  DEVICE_IN_USE: 'DEVICE_IN_USE',
  HARDWARE_ISSUE: 'HARDWARE_ISSUE',
};

export const PREJOIN_LOBBY_HEADER = 'Choose your video and audio settings';
export const JOIN_INTERVIEW = 'Join Interview';

export const LOBBY_NOTIFICATIONS = {
  PERMISSION_DENIED: `<b>Action required:</b> Camera and microphone permissions are required to proceed with your interview. Please enable
 these permissions in your browser/system settings and reload this page.`,
  DEVICE_IN_USE: `<b>Action required:</b> Unable to retrieve video/audio, please close any other applications that might be using the Audio/Video device and retry.`,
  HARDWARE_ISSUE: `<b>Action required:</b> Unable to retrieve video/audio, please check your device hardware settings and retry.`,
};
