/**
 *
 * Asynchronously loads the component for InterviewRooms
 *
 */
import React from 'react';
import { ColorRing } from 'react-loader-spinner';
import loadable from 'utils/loadable';

export default loadable(
  () => import('./index'),
  <div className="h-screen dark:bg-neutral-900 bg-slate-50 flex justify-center items-center">
    <ColorRing
      visible
      height="80"
      width="80"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={['#2563eb', '#2563eb', '#2563eb', '#2563eb', '#2563eb']}
    />
  </div>,
);
