/*
 *
 * GenericButton
 *
 */

import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import dynamicClassNames from 'classnames';
import { ThreeDots } from 'react-loader-spinner';
import { DarkModeContext } from 'utils/themeContext';

export function GenericButton({ onClick, disabled, classNames, children, leadingIcon, validating, type, ...rest }) {
  const { darkMode } = useContext(DarkModeContext) || {};
  return (
    <button
      className={dynamicClassNames(
        'exclude rounded-md font-semibold flex justify-center items-center box-border outline-none ring-none',
        !disabled && 'cursor-pointer',
        classNames && classNames,
      )}
      type="button"
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {validating ? (
        <ThreeDots color={darkMode ? '#ffffff' : '#111827'} height={35} width={30} data-testid="loader" />
      ) : (
        <div className={`flex w-full justify-evenly ${type === 'Effects' ? 'flex-col items-center' : ''}`}>
          {leadingIcon && <div>{leadingIcon}</div>}
          {children && <span>{children}</span>}
        </div>
      )}
    </button>
  );
}

GenericButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  leadingIcon: PropTypes.object,
  validating: PropTypes.bool,
  classNames: PropTypes.string,
};

export default memo(GenericButton);
