import * as sentry from '@sentry/react';
import Mixpanel from 'mixpanel-browser';
import { get, trim } from 'lodash';
import { mixpanelEvents } from 'utils/mixpanelEvents';
import { version } from '../version';

export const trackEvent = ({ event, options, tenant_id_param }) => {
  try {
    const storedUserData = get(JSON.parse(localStorage.getItem('tv.cp.recruit') || '{}'), 'user', {});
    const organizationName = get(storedUserData, 'organization');
    const organizationId = get(
      get(storedUserData, 'https://hasura.io/jwt/claims'),
      'x-hasura-organization-id',
      tenant_id_param || null,
    );
    const defaultOptions = {
      Application: 'interview-rooms',
      'Application Version': version || null,
      'Application Environment': process.env.NODE_ENV || 'Local',
      'Organization name': organizationName || null,
      'Organization Id': organizationId || null,
      ...options,
    };
    if (get(Mixpanel, '__loaded')) Mixpanel.track(event, { ...defaultOptions });
    // eslint-disable-next-line no-console
    console.log('Mixpanel Event:', event, { ...defaultOptions });
  } catch (e) {
    sentry.captureException(e);
  }
};

export const eventLogAction = ({ payload, event }) => {
  const {
    meetingId,
    participantId,
    participantRole,
    tenantIdParam,
    videoServiceProvider,
    scheduledDuration,
    actualDuration,
    timeDiff,
    apiFailed,
    error,
    loadingTime,
    fallbackProvider,
    totalParticipantsJoined,
    candidateParticipantStatus,
    organizerParticipantStatus,
    numberOfInterviewersJoined,
    switchedLanguageFrom,
    switchedLanguageTo,
    preJoinVideoSwitchedFrom,
    preJoinVideoSwitchedTo,
    preJoinAudioSwitchedFrom,
    preJoinAudioSwitchedTo,
    preJoinAudioStatus,
    preJoinVideoStatus,
    participantTimeSpentInLobby,
    preJoinSettingChange,
    rating,
    reviewProvided,
    vp9Enabled,
    videoCaptureDefaultResolution,
  } = payload || {};
  const commonProperties = {
    'Meeting Id': meetingId ? Number(trim(meetingId)) : null,
    'Meeting Participant Id': participantId || null,
    'Role of Participant': participantRole || null,
    'Video Service Provider': videoServiceProvider || null,
  };

  const events = eventType => {
    switch (eventType) {
      case get(mixpanelEvents, 'MEETING_PARTICIPANT_ERROR_DROP'):
      case get(mixpanelEvents, 'MEETING_PARTICIPANT_REJOIN_ERROR_DROP'):
        return { ...commonProperties, 'api call failed': apiFailed, 'error message': error };
      case get(mixpanelEvents, 'MEETING_SCHEDULED_ACTUAL_DURATION_TAT'):
        return { ...commonProperties, 'meeting scheduled actual duration tat': timeDiff };
      case get(mixpanelEvents, 'ACTUAL_DURATION'):
        return { ...commonProperties, 'actual duration': actualDuration };
      case get(mixpanelEvents, 'MEETING_STARTED'):
      case get(mixpanelEvents, 'SCHEDULED_DURATION'):
        return { ...commonProperties, 'scheduled duration': scheduledDuration };
      case get(mixpanelEvents, 'MEETING_LOADING_TIME_TAKEN'):
        return { ...commonProperties, 'loading time': loadingTime };
      case get(mixpanelEvents, 'INTERVIEW_ROOMS_FALLBACK_VIDEO_SERVICE_PROVIDER'):
        return { ...commonProperties, 'fallback video service provider': fallbackProvider };
      case get(mixpanelEvents, 'MEETING_ENDED'):
        return {
          ...commonProperties,
          'Number of Participants joined': totalParticipantsJoined,
          'Organizer Participation Status': organizerParticipantStatus,
          'Candidate Participation Status': candidateParticipantStatus,
          'Number of Interviewers joined': numberOfInterviewersJoined,
          'Scheduled Duration': scheduledDuration,
          'Actual Duration': actualDuration,
        };
      case get(mixpanelEvents, 'CODE_JAM_PARTICIPANT_LANGUAGE_CHANGED'):
        return { ...commonProperties, 'Default Language': switchedLanguageFrom, 'Next Language': switchedLanguageTo };
      case get(mixpanelEvents, 'PRE_JOIN_VIDEO_TOGGLE_SWITCHED'):
        return {
          ...commonProperties,
          'Old Status': preJoinVideoSwitchedFrom,
          'New Status': preJoinVideoSwitchedTo,
        };
      case get(mixpanelEvents, 'PRE_JOIN_AUDIO_TOGGLE_SWITCHED'):
        return {
          ...commonProperties,
          'Old Status': preJoinAudioSwitchedFrom,
          'New Status': preJoinAudioSwitchedTo,
        };
      case get(mixpanelEvents, 'PRE_JOIN_PARTICIPANT_LANDED'):
        return {
          ...commonProperties,
          'Audio Toggle Status': preJoinAudioStatus,
          'Video Toggle Status': preJoinVideoStatus,
        };
      case get(mixpanelEvents, 'PRE_JOIN_PARTICIPANT_TIME_SPENT'):
        return {
          ...commonProperties,
          'Time Spent in Seconds': participantTimeSpentInLobby,
        };
      case get(mixpanelEvents, 'PRE_JOIN_PARTICIPANT_SETTING_CHANGED'):
        return {
          ...commonProperties,
          'Setting Changed Detail': preJoinSettingChange,
        };
      case get(mixpanelEvents, 'POST_INTERVIEW_FEEDBACK_RATING_SELECTED'):
        return {
          ...commonProperties,
          'Rating selected by the user': rating,
        };
      case get(mixpanelEvents, 'POST_INTERVIEW_FEEDBACK_REVIEW_PROVIDED'):
        return {
          ...commonProperties,
          'Review Provided by the User': { rating, reviewProvided },
        };
      case get(mixpanelEvents, 'VP9_ENABLED'):
        return {
          ...commonProperties,
          'vp9 enabled': vp9Enabled,
        };
      case get(mixpanelEvents, 'VIDEO_CAPTURE_DEFAULT_RESOLUTION'):
        return {
          ...commonProperties,
          'Video Capture default resolution ': videoCaptureDefaultResolution,
        };
      default:
        return { ...commonProperties };
    }
  };

  trackEvent({
    event,
    tenantIdParam,
    options: events(event),
  });
};
