import { LocalAudioTrack, Room, RoomEvent, ScreenSharePresets, Track, VideoPresets } from 'livekit-client';
import { warn } from 'utils/logger';

const LivekitService = () => {
  const connect = async options => {
    const roomOptions = {
      // automatically manage subscribed video quality
      adaptiveStream: true,
      // optimize publishing bandwidth and CPU for published tracks
      dynacast: true,
      publishDefaults: {
        simulcast: true,
        screenShareEncoding: ScreenSharePresets?.h720fps5?.encoding,
      },
      videoCaptureDefaults: {
        resolution: VideoPresets.h720.resolution,
      },
      audioCaptureDefaults: {
        autoGainControl: true,
        echoCancellation: true,
        noiseSuppression: true,
      },
    };
    if (options.vp9Enabled) {
      roomOptions.publishDefaults.videoCodec = 'vp9';
    }
    if (options.videoCaptureDefaultResolution && VideoPresets[options.videoCaptureDefaultResolution]) {
      roomOptions.videoCaptureDefaults.resolution = VideoPresets[options.videoCaptureDefaultResolution].resolution;
    }
    const room = new Room(roomOptions);
    return room
      .connect(options.url, options.providerToken)
      .then(() => {
        const videoTrack = options.tracks?.find(track => track.kind === 'video');
        const audioTrack = options.tracks?.find(track => track.kind === 'audio');
        if (videoTrack) room?.localParticipant?.publishTrack(videoTrack).catch(options.onError);
        if (audioTrack) room?.localParticipant?.publishTrack(audioTrack).catch(options.onError);
        room.setMaxListeners(13);
        options.onSuccess(room);
        room.on(RoomEvent.Disconnected, () => {
          options.onDisconnect({ tracks: options.tracks });
        });

        room.on(RoomEvent.LocalTrackPublished, async trackPublication => {
          if (options.onLocalTrackPublished) options.onLocalTrackPublished(trackPublication);
          // to be moved out to main file if this service is common to all the providers
          if (
            trackPublication.source === Track.Source.Microphone &&
            trackPublication.track instanceof LocalAudioTrack &&
            options.noiceCancellationEnabled
          ) {
            const { KrispNoiseFilter, isKrispNoiseFilterSupported } = await import('@livekit/krisp-noise-filter');
            if (!isKrispNoiseFilterSupported()) {
              warn('enhanced noise filter is currently not supported on this browser');
              return;
            }
            // once instantiated the filter will begin initializing and will download additional resources
            const krispProcessor = KrispNoiseFilter();
            await trackPublication.track.setProcessor(krispProcessor);
          }
        });
      })
      .catch(e => {
        e.message = `Error connecting to livekit room: ${e?.message}`;
        options.onError(e);
      });
  };

  return {
    connectProvider: connect,
  };
};

export default LivekitService;
