import { get } from 'lodash';
import * as Sentry from '@sentry/react';
import Apollo from './apollo';

class Auth {
  constructor() {
    this.expires = null;
    if (!Auth.instance) {
      Auth.instance = this;
    }
  }

  get stored() {
    let st = {};
    try {
      st = JSON.parse(localStorage.getItem('tv.cp') || '{}');
    } catch (e) {
      Sentry.captureException(e);
    }
    return st;
  }

  get graphToken() {
    return get(this.stored, 'access_token', null);
  }

  get defaultRole() {
    return get(this.stored, 'default_role', []);
  }

  get recruitStored() {
    let st = {};
    try {
      st = JSON.parse(localStorage.getItem('tv.cp.recruit') || '{}');
    } catch (e) {
      Sentry.captureException(e);
    }
    return st;
  }

  get recruitGraphToken() {
    return get(this.recruitStored, 'access_token', null);
  }

  get recruitDefaultRole() {
    return get(this.stored, 'role', []);
  }

  removeClient() {
    Apollo.removeClient();
  }

  async clientNoAuth() {
    return Apollo.createNoAuthClient();
  }

  async client() {
    return Apollo.createClient({ graphToken: this.graphToken, role: this.defaultRole });
  }

  async recruitClient() {
    return Apollo.createRecruitClient({ graphToken: this.recruitGraphToken });
  }

  async logout() {
    localStorage.removeItem('tv.cp');
    await Apollo.clearCache();
    await Apollo.removeClient();
  }
}

export const instance = new Auth();

export default instance;
