import {
  faCircleDot,
  faVideo,
  faCircleInfo,
  faWifiExclamation,
  faTriangleExclamation,
  faClock,
  faMessage,
  faBell,
  faUserSlash,
} from '@fortawesome/pro-regular-svg-icons';
import {
  virtualCamera,
  cameraSwitch,
  multipleFaces,
  noFaceDetected,
  microphoneDetected,
  microphoneSwitch,
  tabSwitchDark,
  tabSwitchLight,
  SecondaryCameraLandscapeInstructions,
  SecondaryCameraVerticalInstructions,
  secondaryCamDark,
  secondaryCamLight,
} from 'images/index';

export const IR_SIGNAL_TYPES = {
  INTERACTION: 'INTERACTION',
  NOTIFICATION: 'NOTIFICATION',
  ALERT: 'ALERT',
};

export const IR_SIGNAL_SOURCE = {
  SELF: 'self',
};

export const IR_MESSAGE_TYPES = {
  DEFAULT: 'DEFAULT',
  SESSION_ENDS_SOON: 'SESSION_ENDS_SOON',
  VIDEO_REQUEST: 'VIDEO_REQUEST',
  VIDEO_REQUEST_DENIED: 'VIDEO_REQUEST_DENIED',
  JOINED_MEETING: 'JOINED_MEETING',
  LEAVE_MEETING: 'LEAVE_MEETING',
  VIRTUAL_CAMERA: 'VIRTUAL_CAMERA',
  MULTIPLE_CAMERA: 'MULTIPLE_CAMERA',
  CAMERA_SWITCH: 'CAMERA_SWITCH',
  RECORDING_STOPPED: 'RECORDING_STOPPED',
  MICROPHONE_SWITCH: 'MICROPHONE_SWITCH',
  MULTIPLE_MICROPHONE: 'MULTIPLE_MICROPHONE',
  NO_FACE_DETECTED: 'NO_FACE_DETECTED',
  MULTIPLE_FACES_DETECTED: 'MULTIPLE_FACES_DETECTED',
  POOR_INTERNET_CONNECTION: 'POOR_INTERNET_CONNECTION',
  BROWSER_CAMERA_PERMISSION_DENIED: 'BROWSER_CAMERA_PERMISSION_DENIED',
  SESSION_MIN_DURATION: 'SESSION_MIN_DURATION',
  OPEN_CODE_JAM: 'OPEN_CODE_JAM',
  OPEN_BOARD: 'OPEN_BOARD',
  TAB_SWITCH_DETECTED: 'TAB_SWITCH_DETECTED',
  TAB_SWITCH_DETECTED_THRESHOLD_TIME: 'TAB_SWITCH_DETECTED_THRESHOLD_TIME',
  NOTIFY_TO_JOIN: 'NOTIFY_TO_JOIN',
  PARTICIPANT_UNREACHABLE: 'PARTICIPANT_UNREACHABLE',
  PANEL_VIDEO_QUALITY_DEGRADED: 'PANEL_VIDEO_QUALITY_DEGRADED',
  CANDIDATE_POOR_INTERNET_CONNECTION: 'CANDIDATE_POOR_INTERNET_CONNECTION',
  RECONNECTING_SERVICE_PROVIDER: 'RECONNECTING_SERVICE_PROVIDER',
  SESSION_RECORDING_MSG: 'SESSION_RECORDING_MSG',
  SECONDARY_CAM: 'SECONDARY_CAM',
};

export const IR_SIGNAL_ICONS = {
  VIRTUAL_CAMERA: {
    dark: virtualCamera,
    light: virtualCamera,
  },
  MULTIPLE_CAMERA: {
    dark: virtualCamera,
    light: virtualCamera,
  },
  CAMERA_SWITCH: {
    dark: cameraSwitch,
    light: cameraSwitch,
  },
  MULTIPLE_FACES_DETECTED: {
    dark: multipleFaces,
    light: multipleFaces,
  },
  NO_FACE_DETECTED: {
    dark: noFaceDetected,
    light: noFaceDetected,
  },
  MULTIPLE_MICROPHONE: {
    dark: microphoneDetected,
    light: microphoneDetected,
  },
  MICROPHONE_SWITCH: {
    dark: microphoneSwitch,
    light: microphoneSwitch,
  },
  TAB_SWITCH_DETECTED: {
    dark: tabSwitchDark,
    light: tabSwitchLight,
  },
  TAB_SWITCH_DETECTED_THRESHOLD_TIME: {
    dark: tabSwitchDark,
    light: tabSwitchLight,
  },
  SECONDARY_CAMERA_LANDSCAPE_INSTRUCTIONS: {
    light: SecondaryCameraLandscapeInstructions,
    dark: SecondaryCameraLandscapeInstructions,
  },
  SECONDARY_CAMERA_VERTICAL_INSTRUCTIONS: {
    light: SecondaryCameraVerticalInstructions,
    dark: SecondaryCameraVerticalInstructions,
  },
  SECONDARY_CAM: {
    dark: secondaryCamDark,
    light: secondaryCamLight,
  },
  VIDEO_REQUEST_DENIED: faVideo,
  RECORDING_STOPPED: faCircleDot,
  DEFAULT: faCircleInfo,
  POOR_INTERNET_CONNECTION: faWifiExclamation,
  BROWSER_CAMERA_PERMISSION_DENIED: faTriangleExclamation,
  SESSION_ENDS_SOON: faTriangleExclamation,
  SESSION_MIN_DURATION: faClock,
  NEW_CHAT_MESSAGE: faMessage,
  NOTIFY_TO_JOIN: faBell,
  PARTICIPANT_UNREACHABLE: faUserSlash,
  PANEL_VIDEO_QUALITY_DEGRADED: faTriangleExclamation,
  CANDIDATE_POOR_INTERNET_CONNECTION: faTriangleExclamation,
  SESSION_RECORDING_MSG: faCircleDot,
};

export const ALERT_TITLE = {
  VIRTUAL_CAMERA: 'Virtual camera detected',
  MULTIPLE_CAMERA: 'Multiple camera detected',
  CAMERA_SWITCH: 'Candidate switched camera',
  MULTIPLE_MICROPHONE: 'Multiple microphone detected',
  MICROPHONE_SWITCH: 'Switched audio device',
  MULTIPLE_FACES_DETECTED: 'Multiple faces detected',
  NO_FACE_DETECTED: 'Face not detected',
  TAB_SWITCH_DETECTED: 'Switched tab',
  TAB_SWITCH_DETECTED_THRESHOLD_TIME: 'Window focus out',
};

export const ALERT_SUB_TITLE = {
  VIRTUAL_CAMERA: "on candidate's device",
  MULTIPLE_CAMERA: "on candidate's device",
  NO_FACE_DETECTED: 'Candidate is not currently visible on the camera. Please check their position.',
  MULTIPLE_FACES_DETECTED: 'Heads up! The system has identified multiple individuals.',
  MULTIPLE_MICROPHONE: "on candidate's device",
  TAB_SWITCH_DETECTED: 'Candidate switched to a different tab',
  TAB_SWITCH_DETECTED_THRESHOLD_TIME: 'Candidate is out of interview window for over 30 seconds.',
};

export const INTERACTION_MODEL_TITLE = {
  'Video Requested': 'Turn on video',
};

export const INTERACTION_MODEL_CONTENT = {
  'Video Requested': 'Panel has requested to turn on your video',
};

export const ALERT_DESCRIPTION = {
  RECORDING_STOPPED: 'Candidate has not provided consent to record.',
  PANEL_VIDEO_QUALITY_DEGRADED: 'Please check your internet or turn off your video for better quality',
  CANDIDATE_POOR_INTERNET_CONNECTION: 'Please check your internet or find a better spot.',
};

export const NOTIFICATION_TYPE = {
  INFO: 'info',
  WARNING: 'warning',
};

export const SIGNAL_MESSAGE = {
  PANEL_VIDEO_QUALITY_DEGRADED: 'Poor internet connection!',
  CANDIDATE_POOR_INTERNET_CONNECTION: 'Poor internet connection!',
  CANDIDATE_RECONNECTING_SERVICE_PROVIDER: 'Please wait while candidate is reconnecting',
  PANEL_RECONNECTING_SERVICE_PROVIDER: 'Please wait while interviewer is reconnecting',
  SHARING_YOUR_SCREEN: 'You are sharing your screen',
  SCREEN_SHARE_FAILED: 'Action needed: Allow screen sharing in your browser or system settings',
  ENABLE_CAMERA_PERMISSIONS:
    'Action needed: Camera control (on/off) is not working due to browser or system permissions. Please check and adjust your settings to enable full functionality',
  ENABLE_MIC_PERMISSIONS:
    'Action needed: Mic control (mute/unmute) is not working due to browser or system permissions. Please check and adjust your settings to enable full functionality',
  VIDEO_DEVICE_IN_USE:
    'Action required: Unable to retrieve video, please close any other applications that might be using the Video device and retry',
  VIDEO_DEVICE_HARDWARE_ISSUE:
    'Action required: Unable to retrieve video, please check your device hardware settings and retry',
  BOTH_MIC_CAM_DENIED: 'Enable Camera and Microphone permissions in your browser settings.',
  CAM_DENIED: 'Enable Camera permissions in your browser settings.',
  MIC_DENIED: 'Enable Microphone permissions in your browser settings.',
  SESSION_RECORDING_MSG: 'This interview is being recorded.',
  CANDIDATE_RECEIVED_NOTIFICATION: 'Candidate received notification...',
  CANDIDATE_ACCEPTED_REQUEST: 'Candidate accepted your request...',
  CANDIDATE_PHONE_CAMERA_CONNECTED: 'Candidate phone camera is connected.',
  CONNECTION_SUCCESSFUL: 'Connection successful.',
};

export const IR_SIGNAL_MESSAGE_TYPE = [
  'RECONNECTING_SERVICE_PROVIDER',
  'CANDIDATE_RECEIVED_NOTIFICATION',
  'CANDIDATE_ACCEPTED_REQUEST',
];
